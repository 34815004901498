.pre-table-div-2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 10px;
    // background-color: red;
  }
  
  // .pre-table-div-2 > div {
  //   display: flex;
  //   align-items: center;
  //   margin: 10px;
  //   height: 90px;
  // }
  
  .pre-table-div-2 > * {
    display: flex;
    align-items: center;
    margin: 10px;
    height: 40px;
    // text-align: center;
  }
  
  .tenant {
    max-width: 1600px;
    background-color: white;
    margin: 50px auto;
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  }