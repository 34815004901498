.navbar {
  background-color: white;
  z-index: 100;
  padding: 0px 30px;
  box-sizing: border-box;
  margin: 0;
  box-shadow : 0px 18px 50px -27px rgba(0, 0, 0, 0.75);
  height: 60px;
  padding: 10px;
}

.logo-image-nav {
  height: 2em;
}

.root {
  display: grid;
  margin: 0;
  padding: 0;
  grid-template-rows: 50px 1fr 50px;
  height: 100vh;
  width: 100vw;
}

.footer {
  display: flex;
  grid-row: -2 / -1;
  background-color: #3498db;
  justify-content: center;
  align-items: center;
  padding: 10px 30px;
}

.footer > div {
  flex: 1;
}

.left {
  text-align: end;
}

nav {
  display: flex;
  height: 50px;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}

nav > li {
  list-style: none;
  width: 100px;
  margin-right: 33px;
}

.menu {
  flex: 1;
  display: flex;
  max-width: 500px;
  padding: 0;
  margin: 0;
  justify-content: space-around;
  background-color: white;
}

.menu > div {
  list-style: none;
  flex: 1;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.menu-div {
  display: flex;
  flex-direction: column;
  width: 20px;
  padding: 0;
  vertical-align: middle;
}

.menu-div > .line {
  display: block;
  margin: 2px;
  padding: 1px;
  flex: 1;
  background-color: rgba(255, 255, 255, 0.9);
}

.crumb {
  /* border: black 1px solid; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.animate-left {
  animation-duration: 1s;
  animation-name: moveleft;
}

.nav-link {
  all: unset;
  cursor: pointer;
  font-size: 18px;
  font-weight: 400;
}

@media (max-width: 480px) {
  nav :nth-child(1) {
    order: 1;
  }
  nav :nth-child(2) {
    order: 3;
  }
  nav :nth-child(3) {
    order: 4;
  }
  nav :nth-child(4) {
    order: 2;
  }

  .profile-name-text {
    display: none;
  }

  .sign-out-img {
    position: absolute;
    right: 85px;
    top: 10px;
  }

  .menu-div:hover + .menu {
    border: 1px solid red;
  }
  .menu {
    position: fixed;
    width: 50%;
    height: 100%;
    bottom: 0;
    right: 0;
    flex-direction: column;
    height: calc(100vh - 50px);
    transform: translateX(100%);
    transition: all 0.3s ease-in-out;
  }
  .menu.show-menu {
    transform: translateX(0);
  }
  .crumb {
    text-align: center;
    vertical-align: middle;
    transform: translateX(100%);
    min-height: 100px;
    max-height: 100px;
    min-width: 100px;
    max-width: 100px;
    border-radius: 50%;
    /* background-color: hsla(0, 0%, 100%, 0.25); */
    background-image: radial-gradient(hsla(0, 0%, 100%, 0.4), hsla(0, 0%, 100%, 0));
    transition: all 300ms ease;
  }

  .crumb:hover {
    min-width: 100%;
    min-height: calc(100% / 3);
    border-radius: 0;
  }

  .crumb.animate-left {
    transform: translateX(0);
  }
  .line {
    transition: all 0.3s ease-in-out;
  }

  .line1.cross {
    transform-origin: center;
    transform: translate(0%, 3px) rotate(135deg);
    transition: all 0.3s ease-in-out;
  }
  .line2.cross {
    display: none;
    transition: all 0.3s ease-in-out;
  }
  .line3.cross {
    transform-origin: center;
    transform: translate(0%, -3px) rotate(-135deg);
    transition: all 0.3s ease-in-out;
  }

  .footer {
    padding: 2px;
  }
}

@media (min-width: 481px) {
  .large-hidden {
    display: none;
  }
}

@keyframes moveleft {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

.sign-out-img {
  height: 2em;
  cursor: pointer;
  transition: height 300ms ease;
}

.sign-out-img:hover{
  // scale: 1.3;
  height: 2.25  em;
}

.profile-name > div {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.profile-name > div > span {
  white-space: pre;
  margin-right: 20px;
}

.crumb .active {
    height: 43px;
    line-height: 3;
    border-bottom: 6px solid #f07539;
}