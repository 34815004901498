.bread-crumb {
  display: flex;
  flex-direction: row;
  height: 3em;
  padding-top: 20px;
  margin-left: 12px;
}

.crumbs {
  padding: 10px;
  font-size: 18px;
}

.crumbs > a {
  font-size: 18px;
}

.crumbs-seperator {
  padding: 10px 0px;
  font-size: 18px;
}

.crumbs-link {
  color: grey;
}
