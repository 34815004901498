.other-page-container {
    .first-section {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .card-view {
        box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
        margin: 16px;
        background: #fff;
        border-radius: 16px;
        padding: 16px;

        .card-title {
            font-weight: 600;
            font-size: 18px;
        }
    }

    .monthly-count-view,
    .multiselect-tenant-view {
        width: 45%;
    }

    .form-block {
        display: flex;
        align-items: center;
    }
    .form-item {
        width: 100%;
    }

    #button-save {
        color: white !important;
        background-color: #f07539 !important;
    }

    .form-item-getbtn {
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }
    @media screen and (min-width:240px) and (max-width:1024px) {
        /* for screens that are at least 240 pixels wide but less than or equal to 1024 pixels wide */
        .form-block {
            flex-wrap: wrap;
        }
        .monthly-count-view, .multiselect-tenant-view {
            width: 100%;
        }
    }
}
