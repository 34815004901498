.form-input {
  box-sizing: border-box;
  background-color: #ffffff;
  border: 1px solid rgba(150, 150, 150, 0.6);
  width: 100%;
  height: 2.5em;
  border-radius: 5px;
  padding-left: 8px;
  max-width: 400px;
  font-size: 16px;
  padding-right: 8px;
}

.form-input:disabled {
  background-color: #f7f7f7;
}

.form-radio {
  max-width: 400px;
  margin-top: 15px;
}

.form-label {
  display: block;
  font-size: 13px;
  margin-bottom: 4px;
  font-weight: 600;
  color: rgba($color: #404040, $alpha: 0.9);
}

.form-item {
  padding: 5px;
  margin: 15px 5px;
}

.form-item.button {
  display: flex;
  margin-top: 15px;
  border-top: solid 2px rgba(150, 150, 150, 0.15);
}

.form-button {
  box-sizing: border-box;
  width: 100%;
  height: 3em;
  border: none;
  background-color: #f07539;
  padding: 10px 20px;
  cursor: pointer;
  color: #fff;
  margin-top: 30px;
  max-width: 200px;
  min-width: 100px;
  margin-left: 30px;
  transition: background-color 300ms ease-in;
}

@media (max-width: 480px) {
  .form-button {
    // min-width:50px
    margin-left: 15px;
  }
}

.form-button:disabled {
  background-color: #a7a7a7;
}

.form-button:hover:enabled {
  color: #fff;
  background-color: #3498db;
  transition: 0.8s;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  box-shadow: 0 0 0px 1000px white inset;
}

.input-danger {
  border: solid 1px red;
}

.button-wrapper {
  display: flex;
}

.button-wrapper .removeTenant {
  width: 55px;
  margin-left: -15px;
}

.button-wrapper-with-margin {
  display: flex;
  margin-left: auto;
}

.button-wrapper-with-margin button {
  margin: 10px;
}

.button-wrapper-with-margin>#button-save {
  color: white !important;
  background-color: #f07539 !important;
}

.button-wrapper-with-margin>#button-save:disabled {
  color: #00000042 !important;
  background-color: #0000001f !important;
}

input::-webkit-inner-spin-button {
  display: none;
  -webkit-appearance: none;
}

input::-webkit-calendar-picker-indicator {
  color: #3498db;
}

input[type="radio"i] {
  background-color: red;
  cursor: default;
  margin: 3px 0.5ex;
  padding: initial;
  border: initial;
}

.saved {
  background-color: green;
}

.error {
  border: solid 1px red;
}


.form-label-error {
  display: block;
  // position: fixed;
  font-size: 11px;
  color: red;
  margin-top: 10px;
}

.form-item-error {
  color: red;
  font-size: 15px;
  padding-left: 10px;
}