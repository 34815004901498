.login-container {
  display: flex;
  justify-content: center;
  height: calc(100vh);
  align-items: center; 
  background-color: #F7F7F7;
}

.login-card {
  background-color: #ffffff;
  height: 400px;
  width: 300px;
  border: 1px solid #e6eaee;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.02);
  border: 1px solid #e6eaee;
  padding: 50px 60px 50px 60px;
}

h1{
    font-weight: 400;
    padding: 0 10px;
    margin-bottom: 60px;
}

.logo-image{
    height: 1.2em;
    position: relative;
    top: 6px;
}

.login-button {
  justify-content: space-around;
}
