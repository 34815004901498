.page-container {
  // background-color: #f7f7f7;
  background-color: transparent;
  height: 100%;
  width: 100%;
}

.tenant-page-container {
  max-width: 420px;
  margin: 20px auto;
  background-color: white;
  padding: 1px 30px;
  width: 70%;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 27px 7px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
}


// .button-cancel{
//     background-color: white;
//     border: solid 2px rgb(0, 200, 0);
//     color: rgb(0, 200, 0);
//     transition: all 0.4s ease-in-out;
// }

// .button-cancel:hover{
//     color: white;
//     background-color: rgb(0, 200, 0);
//     border: solid 2px rgb(0, 200, 0);
// }

.button-delete {
  color: white;
  background-color: red;
}

span.red-clr {
  color: red;
}
.currencyInput .MuiInput-root {
  width: 100%;
}

.custom-tooltip {
  position: relative;
}

.custom-tooltip::after {
  display: none; /* This hides the arrow */
}