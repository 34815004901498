.tenant {
  width: 90%;
  max-width: 1200px;
  background-color: white;
  margin: 50px auto;
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
}

.table-wrapper {
  display: flex;
  flex-direction: column;
  box-shadow: 1px 0px 44px -21px rgba(0,0,0,0.75);
  border-radius: 12px;
}

.pre-table-div {
  display: flex;
  flex-direction: row;
  height: 5em;
  justify-content: space-between;
}

.green {
  color: green;
}

.red {
  color: red;
}

.ReactTable .rt-tbody .rt-td {
  border-right: 1px solid rgba(0, 0, 0, 0.02);
  padding: 10px 37px;
}

.ReactTable .rt-tr-group {
  line-height: 1.8;
}

.ReactTable .rt-thead .rt-resizable-header-content {
  font-weight: 700;
  color: rgba(25, 25, 25, 0.8);
  padding: 10px 37px 5px 30px;
  text-align: left;
  font-size: 15px;
  line-height: 2;
  background-color: whitesmoke;
  box-sizing: border-box;
}

.ReactTable .rt-thead .rt-tr {
  background-color: whitesmoke;
}

h2 {
  margin: 27px 0 0 32px;
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  color: #000000;
}

.ReactTable .rt-thead .rt-resizable-header {
  border: none;
}

.ReactTable .rt-th,
.ReactTable .rt-td {
  border: none;
}

@media (max-width: 480px) {
  .ReactTable .rt-thead .rt-resizable-header-content {
    padding: 4px;
  }

  .ReactTable .rt-tbody .rt-td {
    padding: 4px;
    font-size: 10px;
  }
}

input {
  border: none;
  // margin-left: -14px;
}

.pre-table-div-2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  // background-color: red;
}

// .pre-table-div-2 > div {
//   display: flex;
//   align-items: center;
//   margin: 10px;
//   height: 90px;
// }

.pre-table-div-2 > * {
  display: flex;
  align-items: center;
  margin: 10px;
  height: 40px;
  // text-align: center;
}

.pre-table-div-2 > button {
  height: 40px;
  width: 40px;
  // max-width: 40px;
  // position: absolute;
  // padding: 0 30px;
}

.pre-table-div-1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  // background-color: red;
  padding: 20px;
}

.pre-table-div-1 > * {
  display: flex;
  align-items: center;
  margin: 10px;
  height: 30px;
  font-size: 30px;
}


.div-button{
  font-size: 30px;
  color:white;
  background-color: #f47637;
  border: solid 1px #f47637;
  border-radius: 2px;
}

.progressBar {
  margin-left: 47%;
  margin-top: 20%;
}

.tenant-count {
    color: gray;
    font-size: 0.6em;
    padding-top: 8px;
    padding-left: 6px;
}

.ReactTable .rt-noData {
  margin-top: 100px !important;
}

#add-new-button{
  margin-right: 0px;
}

.pre-table-div-2 .MuiInputBase-input {
  height: 0.4em !important;
}

.Mui-focused fieldset {
  border-color: #f07539 !important;
}

label.Mui-focused {
  color: #f07539 !important;
}

.inlineSearchLoader .MuiCircularProgress-root{
  width: 24px !important;
  height: 24px !important;
}

.float-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  align-items: center;
  color: white !important;
  background-color: #f07539 !important;
  padding: 6px 16px;
  font-size: 0.875rem;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  cursor: pointer;
}

.action-drop {
  min-height: 48px;
  background: #ffffff;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  position: absolute;
  right: 0px;
  z-index: 99;
  padding: 8px;
}

.option-list {
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
  padding: 8px;
  white-space: nowrap;
  height: 32px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.option-list:hover {
  background: #fafafa;
}